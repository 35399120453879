<template>
	<div id="mobileForeground">
		<div class="searthBox">
			<el-input placeholder="输入姓名查找医生" v-model="docSearth" clearable>
				<i slot="prefix" class="el-input__icon el-icon-search"></i>
			</el-input>
		</div>

		<div class="kong">
			<div class="item" @click="fellowPatients()">
				<img src="../assets/images/手机.svg" alt="">
				<span>病友群</span>
			</div>
			<b class="len" />
			<div class="item" @click="buyMedicine()">
				<img src="../assets/images/药店.svg" alt="">
				<span>药品团购</span>
			</div>
		</div>

		<div class="DocBox">
			<div class="title">
				数字虚拟医生
				<!-- <el-link type="primary" :underline="false">
					查看全部
					<i class="el-icon-arrow-right"></i>
				</el-link> -->
			</div>

			<div class="DocList">
				<div class="item" v-for="(item,i) in docList" :key="i" @click="windowOpen(item.url)">
					<img :class="item.img" :src="require(`@/assets/images/${item.img}.png`)">
					<span class="name">{{item.name}}</span>
					<span class="keshi">{{item.office}}</span>
					<!-- <div class="mark">
						<img :src="require(`@/assets/images/star.png`)">
						<span>4.8（456）</span>
					</div> -->
				</div>
				
			
			</div>
		</div>

		<el-dialog title="长按保存二维码扫码加群" :visible.sync="dialogVisible" width="90%">
			<img class="qrcode" :src="require(`@/assets/images/病友群.jpg`)" alt="">
		</el-dialog>
	</div>
</template>

<script>
	export default {
		name: "MobileForeground",
		data() {
			return {
				docSearth: '',
				dialogVisible: false,
				docList: [{
					img: 'huang',
					office: '胸外科',
					url: 'https://work.weixin.qq.com/kfid/kfcd1d8a22515006611',
					name: '黄可南'
				}, {
					img: 'hou',
					office: '胸外科',
					url: 'https://work.weixin.qq.com/kfid/kfc900b17e7ce778e26',
					name: '侯静朴'
				}, {
					img: 'hu',
					office: '胸外科',
					url: 'https://work.weixin.qq.com/kfid/kfc4761a1da38b1bba8',
					name: '胡晓星'
				}, {
					img: 'jia',
					office: '放化疗科',
					url: 'https://work.weixin.qq.com/kfid/kfc14ea2155fd464bdd',
					name: '贾敬好'
				}, {
					img: 'sun',
					office: '呼吸与危重症科',
					url: 'https://work.weixin.qq.com/kfid/kfc30b991b7b4967360',
					name: '孙思庆'
				}, {
					img: 'new',
					office: 'COMING SOON',
					url: '#',
					name: '近期发布'
				}]
			}
		},
		created() {},
		mounted() {},
		methods: {
			fellowPatients() {
				console.log('加病友群');
				this.dialogVisible = true;
			},

			buyMedicine() {
				console.log('跳转购药小程序')
			},

			windowOpen(url) {
				if (url != '#') window.open(url);
				else this.$message.error('该医生暂未开通在线问诊服务')
			}
		}
	}
</script>

<style lang="less" scoped>
	#mobileForeground {
		width: 100vw;
		min-height: 100vh;
		display: flex;
		overflow: hidden;
		overflow-y: auto;
		align-items: center;
		flex-direction: column;
		justify-content: flex-start;
		background: #fff url('../assets/images/topbg.png') no-repeat;
		background-size: 100% 240px;
		padding-bottom: 40px;

		::v-deep .el-dialog {
			border-radius: 20px;

			.qrcode {
				width: 70vw;
				display: block;
				margin: auto;
			}
		}

		.searthBox {
			width: 100%;
			padding: 5vw;

			::v-deep .el-input {
				border-bottom: 1px solid #fff;

				.el-input__inner {
					color: #fff;
					border: none;
					background-color: transparent;
				}

				.el-input__icon {
					color: #fff;
				}
			}
		}

		.kong {
			margin-top: 30px;
			display: flex;
			flex-direction: row;
			align-items: center;
			width: 90vw;
			height: 120px;
			border-radius: 20px;
			overflow: hidden;
			box-shadow: 0px 40px 20px rgba(0, 0, 0, 0.02), 0px 16px 6px rgba(0, 0, 0, 0.02), 0px 0px 1px rgba(0, 0, 0, 0.02);

			.item {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				flex: 1;
				background-color: #fff;
				height: 120px;

				img {
					width: 34px;
					height: 34px;
					margin-bottom: 10px;

				}

				span {
					font-size: 16px;
				}
			}

			.len {
				height: 170px;
				width: 1px;
				background-color: #e5e5e5;
			}
		}

		.DocBox {
			width: 90vw;
			margin-top: 30px;

			.title {
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: space-between;
				font-size: 16px;
				font-weight: bold;
				margin-bottom: 20px;

				.el-link {
					font-size: 14px;
				}
			}

			.DocList {
				display: flex;
				flex-wrap: wrap;
				flex: 1;

				.item {
					display: flex;
					flex-direction: column;
					width: 30%;
					margin-right: 5%;
					margin-bottom: 14px;
					justify-content: center;
					align-items: center;
					border: 1px solid #F2F4F5;
					border-radius: 10px;
					padding: 10px;

					&:nth-of-type(3n) {
						margin-right: 0;
					}

					>img {
						width: 100%;
						border-radius: 10px;
						overflow: hidden;
						background-color: #F2F6FE;
					}

					.new {
						background-color: #FFECE9;
					}

					.name {
						color: #112950;
						font-size: 14px;
						font-weight: bold;
						margin: 4px 0 2px;
					}

					.keshi {
						font-size: 12px;
						color: #B2BAC6;
						margin-bottom: 2px;
					}

					.mark {
						display: flex;
						flex-direction: row;
						font-size: 14px;
						align-items: center;

						img {
							width: 16px;
							height: 16px;
						}
					}
				}
			}
		}

	}
</style>